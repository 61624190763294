import { useMemo } from 'react';
import {
  CombinedContentType,
  Page,
  StaticContentTypes,
  StaticDynamicReplacementConfig,
} from '../interfaces';
import { useAvailability } from '@ncs-frontend-monorepo/availability';
import { castToBoolean } from '../utils/helpers';
import {
  ContentType,
  HYBRID_CONTENT_TYPES,
} from '@ncs-frontend-monorepo/content-library';
import {
  replaceDynamicTexts,
  DynamicTextContext,
} from '@ncs-frontend-monorepo/utils';

export interface ProcessedPage {
  page: Page;
  isHybridPage: boolean;
}

export function useProcessedPage(page: Page): ProcessedPage {
  const {
    availability: { address, ftthPresalesInformation },
  } = useAvailability();

  return useMemo(() => {
    const isHybridPage = page.content.some((content) =>
      HYBRID_CONTENT_TYPES.includes(content.component),
    );

    // Check if the page has a dynamic text replacement configuration
    let dynamicReplacementConfigIndex = -1;
    const dynamicReplacementConfig = page.content.find(
      (content: CombinedContentType, index) => {
        const isConfigFound =
          content.component === ContentType.STATIC_CONTENT &&
          content.props?.type == StaticContentTypes.DynamicReplacementConfig;
        if (isConfigFound) dynamicReplacementConfigIndex = index;
        return isConfigFound;
      },
    )?.props as StaticDynamicReplacementConfig | undefined;

    // Replace dynamic texts in the page content if enabled
    if (dynamicReplacementConfig) {
      const getContext = (): DynamicTextContext => {
        const { enabled = 'false', replaceIn = '' } =
          dynamicReplacementConfig.params;
        if (!castToBoolean(enabled)) return {};

        const currentCity = (address?.city || '').toLowerCase();
        const allowedCities = replaceIn
          .split(',')
          .map((city) => city.trim().toLowerCase())
          .filter(Boolean);

        if (allowedCities.length) {
          if (!allowedCities.includes(currentCity)) {
            return {};
          }
        }

        return { address, ftthPresalesInformation };
      };

      const replaceTextsInPageContent = (
        contents: Page['content'],
      ): Page['content'] => {
        const context = getContext();
        return contents.map((content) => {
          content.props = replaceDynamicTexts(content.props, context);
          return content;
        });
      };

      // create a copy of the page to avoid mutating the original
      page = JSON.parse(JSON.stringify(page)) as Page;

      // remove the dynamic replacement config from the page content by index to avoid rendering it
      page.content.splice(dynamicReplacementConfigIndex, 1);

      // replace dynamic texts in the page content
      page.content = replaceTextsInPageContent(page.content);
    }

    return {
      page,
      isHybridPage,
    };
  }, [page.pageId, address, ftthPresalesInformation]);
}
